// Google Maps Key
const GOOGLE_MAPS_API_3 = process.env.GOOGLE_MAPS_API_3;
const googlePin = require('../../../images/icons/g_map/google_maps_pin_default.png');

var mapExecute = (function () {
  var executed = false;
  return function () {
    if (!executed) {
      executed = true;

      $.getScript('https://maps.googleapis.com/maps/api/js?key=' + GOOGLE_MAPS_API_3, function (data, textStatus, jqxhr) {
        if (jqxhr.status === 200) {
          setTimeout(function () {

            initMap();

          }, 200)
        } else {
          console.error('Google maps not initialized');
        }
      });
    }
  };
})();

$(window).on('resize scroll', function () {
  if ($('#map-canvas').isInViewport()) {
    mapExecute();
  }
});
// Initialize and add the map


function initMap() {
  // The location of Uluru
  var gmapLat = $("#map-canvas").data('lat');
  var gmapLng = $("#map-canvas").data('lng');
  var myLatLng = new google.maps.LatLng(gmapLat, gmapLng);
  // The map, centered at Uluru
  var map = new google.maps.Map(
    document.getElementById('map-canvas'), {
      zoom: 14,
      styes: [{
          elementType: 'geometry',
          stylers: [{
            color: '#1a1a1a'
          }]
        },
        {
          elementType: 'labels.text.stroke',
          stylers: [{
            color: '#1a1a1a'
          }]
        },
        {
          elementType: 'labels.text.fill',
          stylers: [{
            color: '#5a7f49'
          }]
        },
        {
          featureType: 'administrative.locality',
          elementType: 'labels.text.fill',
          stylers: [{
            color: '#83b817'
          }]
        },
        {
          featureType: 'poi',
          elementType: 'labels.text.fill',
          stylers: [{
            color: '#83b817'
          }]
        },
        {
          featureType: 'poi.park',
          elementType: 'geometry',
          stylers: [{
            color: '#373737'
          }]
        },
        {
          featureType: 'poi.park',
          elementType: 'labels.text.fill',
          stylers: [{
            color: '#6b9a76'
          }]
        },
        {
          featureType: 'road',
          elementType: 'geometry',
          stylers: [{
            color: '#4f4f4f'
          }]
        },
        {
          featureType: 'road',
          elementType: 'geometry.stroke',
          stylers: [{
            color: '#212a37'
          }]
        },
        {
          featureType: 'road',
          elementType: 'labels.text.fill',
          stylers: [{
            color: '#9ca5b3'
          }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry',
          stylers: [{
            color: '#5a7f49'
          }]
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{
            color: '#1f2835'
          }]
        },
        {
          featureType: 'road.highway',
          elementType: 'labels.text.fill',
          stylers: [{
            color: '#83b817'
          }]
        },
        {
          featureType: 'transit',
          elementType: 'geometry',
          stylers: [{
            color: '#2f3948'
          }]
        },
        {
          featureType: 'transit.station',
          elementType: 'labels.text.fill',
          stylers: [{
            color: '#83b817'
          }]
        },
        {
          featureType: 'water',
          elementType: 'geometry',
          stylers: [{
            color: '#17263c'
          }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.fill',
          stylers: [{
            color: '#515c6d'
          }]
        },
        {
          featureType: 'water',
          elementType: 'labels.text.stroke',
          stylers: [{
            color: '#17263c'
          }]
        }
      ],
      mapTypeControlOptions: {
        mapTypeIds: [google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.SATELLITE, google.maps.MapTypeId.HYBRID]
      },
      navigationControlOptions: {
        style: google.maps.NavigationControlStyle.DEFAULT
      },
      center: myLatLng,
      scrollwheel: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      // mapTypeId: "OSM",
      mapTypeControlOptions: {
        mapTypeIds: [
          "OSM",
          google.maps.MapTypeId.ROADMAP,
          google.maps.MapTypeId.HYBRID
        ]
      }
    });
  //OSM
  map.mapTypes.set("OSM", new google.maps.ImageMapType({
    getTileUrl: function (coord, zoom) {
      return "http://" + String.fromCharCode(Math.floor(Math.random() * 3) + 97) + ".tile.openstreetmap.org/" + zoom + "/" + coord.x + "/" + coord.y + ".png";
    },
    tileSize: new google.maps.Size(256, 256),
    name: "OSM",
    maxZoom: 18
  }));
  // The marker, positioned at Uluru
  var marker = new google.maps.Marker({
    icon: googlePin,
    position: myLatLng,
    map: map
  });
  // var marker = [];
  // var infowindow = [];

  // $.each(mapCoordinates.coordinates, function (k, v) {
  //     marker[k] = new google.maps.Marker({
  //         position: new google.maps.LatLng(v.lat, v.lng),
  //         map: map,
  //         icon: '<: URL_ROOT>/site_resources/images/marker.png'
  //     });
  // });
  // 
  google.maps.event.addDomListener(window, "resize", function () {
    var center = map.getCenter();
    google.maps.event.trigger(map, "resize");
    map.setCenter(center);
  });

}